html {
  background-color: #78FF00;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  background-color: #78FF00;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-wrapper {
  position: relative;
}
.map-wrapper::before, 
.map-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(transparent 30%, #f5eee6);
  z-index: 1;
}
.map-wrapper::after {
  top: -1px;
  height: calc(100% + 2px);
  background: linear-gradient(0deg, #f5eee6, transparent 25%, transparent 75%, #f5eee6);
}